import { InfoCircledIcon } from '@radix-ui/react-icons';
import { Badge, Box, Card, Flex, Heading, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { TrainingHelper } from 'classes/helpers/training-helper';
import { HELP_URLS } from 'classes/helpers/url.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { MIN_CONFIDENCE, PlateCanvas } from 'classes/plate-canvas';
import { CommonCallout } from 'components/common/callouts';
import { SuperAdminIcon } from 'components/common/custom-icon/shorthands';
import { DialogButton } from 'components/common/dialogs/button';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonFormGrid } from 'components/common/form/grid';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { CommonTextInput } from 'components/common/form/text';
import { CommonRadio } from 'components/common/radio';
import { CommonTrainingProgress } from 'components/common/training-progress';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { DetectionFailedCallout } from 'components/machine/detection-failed';
import { DataTable } from 'components/machine/dialogs/preset-training/controls/data-table';
import { PresetTrainingPlateView } from 'components/machine/dialogs/preset-training/plate';
import env from 'config';
import { IAimingContext } from 'contexts/aiming.context';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { IMatchingShotsContext } from 'contexts/pitch-lists/matching-shots.context';
import { IPitchListsContext } from 'contexts/pitch-lists/pitch-lists.context';
import { ITrainingContext } from 'contexts/training.context';
import {
  PrecisionTrainStep,
  ProgressDirection,
  getAdjustmentsOptions,
  getSampleSizes,
} from 'enums/training.enums';
import { t } from 'i18next';
import { IButton } from 'interfaces/i-buttons';
import { EMPTY_PROGRESS, ITrainingProgress } from 'interfaces/i-training';
import {
  FT_TO_INCHES,
  METERS_TO_FT,
  clamp,
} from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict, getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { FireOption } from 'lib_ts/enums/machine.enums';
import { BuildPriority } from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  BETA_PRESET_TRAINING_OPTIONS,
  PRESET_TRAINING_OPTIONS,
  PresetTrainingMode,
} from 'lib_ts/enums/training.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IBallStatusMsg } from 'lib_ts/interfaces/i-machine-msg';
import { IPrecisionTrainLogEventData } from 'lib_ts/interfaces/i-session-event';
import { SpecialMsPosition } from 'lib_ts/interfaces/machine-msg/i-special-mstarget';
import { IBallState, IClosedLoopBuildChars } from 'lib_ts/interfaces/pitches';
import { IPitch } from 'lib_ts/interfaces/pitches/i-pitch';
import { IMachineShot } from 'lib_ts/interfaces/training/i-machine-shot';
import {
  IPresetOption,
  IPresetTrainingSpec,
} from 'lib_ts/interfaces/training/i-preset-training-spec';
import { IRapsodoBreak } from 'lib_ts/interfaces/training/i-rapsodo-shot';
import React from 'react';
import { SessionEventsService } from 'services/session-events.service';
import { StateTransformService } from 'services/state-transform.service';

const COMPONENT_NAME = 'PresetTrainingControls';

const FOOTER_BTN_CLASS = 'width-120px';

// give time for users to see the complete badge before moving ahead
const AUTO_ADVANCE_DELAY_MS = 3_000;

const ROTATION_STEP_SIZE = 0.75;

const DEFAULT_USE_GRADIENT = true;

const ENABLE_ADVANCED_CONTROLS = false;
const ENABLE_FINISH_BUTTON = false;

const STEP_TIMEOUT_MS = 3_000;

const OPTIONS =
  env.identifier === 'beta'
    ? BETA_PRESET_TRAINING_OPTIONS
    : PRESET_TRAINING_OPTIONS;

export const SEEK_RESULT_STEPS = [
  PrecisionTrainStep.SeekSuccess,
  PrecisionTrainStep.SeekFailure,
];

const SEEK_IN_PROGRESS_STEPS = [
  PrecisionTrainStep.SeekBreaks,
  PrecisionTrainStep.SeekSpeed,
  PrecisionTrainStep.SeekSpins,
];

const getStepSize = (sampleSize: number) =>
  clamp(0.9 - Math.pow(0.5, sampleSize), 0.5, 0.9);

/** by default returns results in seconds */
export const getETAForShots = (remaining: number, unit: 'sec' | 'min') => {
  const ESTIMATE_TIME_PER_SHOT_SEC = 15;
  const seconds = ESTIMATE_TIME_PER_SHOT_SEC * remaining;

  if (unit === 'min') {
    return Math.ceil(seconds / 60);
  }

  return seconds;
};

const tPresetMode = (mode: PresetTrainingMode) => {
  switch (mode) {
    case PresetTrainingMode.Balanced: {
      return t('tr.preset-balanced');
    }
    case PresetTrainingMode.Custom: {
      return t('tr.preset-custom');
    }
    case PresetTrainingMode.Precision: {
      return t('tr.preset-precision');
    }
    case PresetTrainingMode.Quick: {
      return t('tr.preset-quick');
    }
    default: {
      return mode;
    }
  }
};

const tPresetDescription = (mode: PresetTrainingMode) => {
  switch (mode) {
    case PresetTrainingMode.Balanced: {
      return t('tr.preset-balanced-desc');
    }
    case PresetTrainingMode.Custom: {
      return t('tr.preset-custom-desc');
    }
    case PresetTrainingMode.Precision: {
      return t('tr.preset-precision-desc');
    }
    case PresetTrainingMode.Quick: {
      return t('tr.preset-quick-desc');
    }
    default: {
      return;
    }
  }
};

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  listsCx: IPitchListsContext;
  aimingCx: IAimingContext;
  trainingCx: ITrainingContext;

  pitches: Partial<IPitch>[];

  calibrating?: boolean;

  showProgress?: boolean;

  // if true, this view's finish/skip buttons will not be shown
  hideNext?: boolean;

  // callback before user manually changes pitch to the next one (e.g. skipping during calibration)
  beforeNext?: () => void;

  // callback when training of every pitch is complete
  onFinish?: () => void;

  /** e.g. for Back button during model building */
  left_button?: IButton;

  /** provided by parent to resume in the middle of a list */
  defaultIndex?: number;
}

interface IState {
  /** true: overrides pretty much every other state, there is a fatal error (e.g. pitch does not have a msDict entry) */
  error: boolean;

  /** which pitch of N it is currently training */
  index: number;

  optimized: boolean;
  newShot?: IMachineShot;

  step: PrecisionTrainStep;

  /** for active/current pitch */
  matches: IMachineShot[];
  /** for displaying past data */
  prevMatches: IMachineShot[];

  /** for suppressing auto-fire actions until a fireresponse arrives (e.g. after toggling it on) */
  ignoreAutoFire?: boolean;

  // while rebuilding a pitch via closed loop
  loading: boolean;

  // range is (0, 1]
  closedLoopStepSize: number;

  // reset to 0 whenever changing pitches, tracks how many previous CL builds have been made
  currentIteration: number;

  // whether or not to use gradient for closed loop
  useGradient: boolean;

  // for manipulating in memory without modifying original pitches until a good ms is found
  pitches: Partial<IPitch>[];

  spec: IPresetTrainingSpec;

  mode: PresetTrainingMode;

  sampleSizes: IOption[];
  adjustments: IOption[];
}

export class PresetTrainingControls extends React.Component<IProps, IState> {
  private init = false;
  private trainedTimeout: any;
  private sendTimeout: any;
  private stepTimeout: any;
  private plate_canvas = PlateCanvas.makeSimple();

  plate?: PresetTrainingPlateView;
  fireButton?: MachineFireButton;

  constructor(props: IProps) {
    super(props);

    const copiedPitches = props.pitches.map(
      (p) => ({ ...p }) as Partial<IPitch>
    );

    const userMode = props.authCx.current.preset_training_mode;
    // defaults to the first option (i.e. should be Quick in all env)
    const safeOption = OPTIONS.find((p) => p.mode === userMode) ?? OPTIONS[0];

    this.state = {
      error: false,
      index: props.defaultIndex ?? 0,
      optimized: false,
      pitches: copiedPitches,
      matches: [],
      prevMatches: [],
      step: PrecisionTrainStep.Firing,
      loading: false,
      currentIteration: 0,
      useGradient: DEFAULT_USE_GRADIENT,
      spec: {
        ...safeOption.spec,
        // in case the sample size is less than the machine threshold
        sampleSize: Math.max(
          props.machineCx.machine.training_threshold,
          safeOption.spec.sampleSize
        ),
      },
      closedLoopStepSize: getStepSize(safeOption.spec.sampleSize),
      mode: safeOption.mode,

      sampleSizes: getSampleSizes(props.machineCx.machine.training_threshold),
      adjustments: getAdjustmentsOptions(),
    };

    this.afterTrainingMsgs = this.afterTrainingMsgs.bind(this);
    this.changePitch = this.changePitch.bind(this);
    this.getProgress = this.getProgress.bind(this);
    this.handleBallStatus = this.handleBallStatus.bind(this);
    this.handleFireResponse = this.handleFireResponse.bind(this);
    this.handlePitchTrained = this.handlePitchTrained.bind(this);
    this.initialize = this.initialize.bind(this);
    this.isLastPitch = this.isLastPitch.bind(this);

    this.renderFireButton = this.renderFireButton.bind(this);
    this.renderFooterButtons = this.renderFooterButtons.bind(this);
    this.renderControls = this.renderControls.bind(this);
    this.renderNextButton = this.renderNextButton.bind(this);
    this.renderPresets = this.renderPresets.bind(this);
    this.renderSendButton = this.renderSendButton.bind(this);
    this.renderStepDescription = this.renderStepDescription.bind(this);

    this.sendPitch = this.sendPitch.bind(this);
    this.setSpec = this.setSpec.bind(this);
    this.updateMatchesAndActive = this.updateMatchesAndActive.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);

    if (!this.init) {
      this.init = true;
      this.initialize();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (
      prevProps.trainingCx.lastUpdated !== this.props.trainingCx.lastUpdated
    ) {
      this.afterTrainingMsgs();
    }
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);

    clearTimeout(this.trainedTimeout);
    clearTimeout(this.sendTimeout);
    clearTimeout(this.stepTimeout);

    this.props.machineCx.setAutoFire(false);
  }

  private async initialize() {
    if (this.state.pitches.length === 0) {
      return;
    }

    await this.props.aimingCx.setPitch(this.state.pitches[0] as IPitch, true);
    this.sendPitch('componentDidMount');
  }

  private handlePitchTrained() {
    clearTimeout(this.trainedTimeout);

    this.trainedTimeout = setTimeout(() => {
      if (this.isLastPitch()) {
        this.props.onFinish?.();
        return;
      }

      if (!this.props.machineCx.autoFire) {
        return;
      }

      this.changePitch(ProgressDirection.Next);
    }, AUTO_ADVANCE_DELAY_MS);
  }

  getTitle(): string {
    let result = t('common.training').toString();

    if (this.state.pitches.length > 1) {
      result += ` ${this.state.index + 1} of ${
        this.state.pitches.length
      } unique pitches`;
    }

    const pitch = this.props.aimingCx.pitch;
    if (pitch?.name) {
      result += `: "${pitch.name}"`;
    }

    return result;
  }

  private async handleBallStatus(event: CustomEvent) {
    const data: IBallStatusMsg = event.detail;

    switch (data.ball_count) {
      case 1: {
        this.sendPitch('handleBallStatus');
        return;
      }

      default: {
        return;
      }
    }
  }

  private async handleFireResponse(event: CustomEvent) {
    /** we can stop ignoring auto-fire status once a fire has already happened */
    this.setState({
      ignoreAutoFire: false,
    });
  }

  private async afterTrainingMsgs() {
    const finalMsg = this.props.trainingCx.getFinalMsg();

    if (!finalMsg) {
      return;
    }

    if (!finalMsg.success) {
      NotifyHelper.warning({
        message_md: finalMsg.message ?? 'Failed to train (unknown error).',
        inbox: true,
        buttons: [
          {
            label: 'Read More',
            onClick: () => window.open(HELP_URLS.RAPSODO_ERRORS),
          },
        ],
      });
    }

    await this.updateMatchesAndActive('afterTrainingMsgs');
  }

  /** sends the active pitch (without additional rotation) */
  private async sendPitch(trigger: string) {
    /** fire button will listen for r2f/fireresponse */
    this.fireButton?.resetR2FWait(`${COMPONENT_NAME} > before send pitch`);

    this.props.aimingCx.sendToMachine({
      training: true,
      skipPreview: true,
      trigger: `${COMPONENT_NAME} > ${trigger}`,
    });
  }

  private async updateMatchesAndActive(source: string) {
    // keep track of this so we know what to do after updating the state
    const prevStep = this.state.step;

    const pitch = this.state.pitches[this.state.index];
    if (!pitch) {
      throw new Error(
        `Invalid pitch (not defined) at index ${this.state.index}`
      );
    }

    if (!pitch._id) {
      throw new Error(`Invalid pitch (no _id) at index ${this.state.index}`);
    }

    const nextState: Partial<IState> = {};

    const matchesBefore = this.state.matches.map((s) => s._id);

    await this.props.aimingCx.updateMatches(
      // ensure we pull enough shots to finish if possible
      Math.max(
        this.state.spec.sampleSize,
        this.props.machineCx.machine.training_threshold
      )
    );

    // might be changed later if we need to rebuild via closed loop
    const aimed = this.props.aimingCx.getAimed({
      training: true,
      stepSize: ROTATION_STEP_SIZE,
    });

    if (!aimed) {
      console.error({
        event: `${COMPONENT_NAME}: failed to rotate pitch to middle`,
      });
      return;
    }

    if (!aimed.ms.matching_hash) {
      console.error(
        `${COMPONENT_NAME}: invalid matching hash after updating matches, cannot draw shots`
      );
      return;
    }

    const matchesAfter = aimed.usingShots;
    nextState.matches = matchesAfter;

    const logData: IPrecisionTrainLogEventData = {
      pitch_id: pitch._id,
      attempt: this.state.currentIteration,
      shots: matchesAfter,
    };

    // prevMatches will not be changed if there are no matches after
    if (matchesAfter.length > 0) {
      nextState.prevMatches = matchesAfter;
    }

    const newShot = matchesAfter.find((s) => !matchesBefore.includes(s._id));
    nextState.newShot = newShot;

    if (!newShot) {
      console.warn(
        `${COMPONENT_NAME}: failed to find a new shot while updating matches`
      );
    }

    const skippingValidation = this.props.machineCx.fireOptions.includes(
      FireOption.SkipRapsodoValidation
    );

    const showConfidenceWarning =
      // admins always see low confidence warnings
      this.props.authCx.current.role === UserRole.admin ||
      // non-admins only see low confidence warnings while not skipping rapsodo validation
      !skippingValidation;

    if (
      showConfidenceWarning &&
      newShot?.confidence &&
      !TrainingHelper.isConfidenceSufficient({
        confidence: newShot.confidence,
        minValue: MIN_CONFIDENCE,
      })
    ) {
      NotifyHelper.warning({
        message_md: `Low Rapsodo confidence detected.`,
        buttons: [
          {
            label: 'common.read-more',
            onClick: () => window.open(HELP_URLS.RAPSODO_ERRORS),
          },
          {
            label: 'common.skip-validation',
            // avoid showing if you're already skipping (e.g. admin always sees toast)
            invisible: skippingValidation,
            dismissAfterClick: true,
            onClick: () =>
              this.props.machineCx.addFireOption(
                FireOption.SkipRapsodoValidation
              ),
          },
        ],
      });
    }

    const requireConfidence =
      !this.props.calibrating &&
      !this.props.machineCx.fireOptions.includes(
        FireOption.SkipRapsodoValidation
      );

    const SUMMARY_FN = MiscHelper.getMedianObject;

    const nextStep = !this.state.optimized
      ? this.plate_canvas.preOptimizeStep({
          prevStep: prevStep,
          spec: this.state.spec,
          currentIteration: this.state.currentIteration,
          summaryFn: SUMMARY_FN,
          pitch: pitch,
          shot: newShot,
          allShots: matchesAfter,
        })
      : this.plate_canvas.postOptimizeStep({
          prevStep: prevStep,
          pitch: pitch,
          shot: newShot,
          allShots: matchesAfter,
          threshold: this.state.spec.sampleSize,
          requireConfidence: requireConfidence,
        });

    // is undefined unless seek is done (as failure or success)
    const nextOptimizedStep = SEEK_RESULT_STEPS.includes(nextStep)
      ? this.plate_canvas.postOptimizeStep({
          prevStep: prevStep,
          pitch: pitch,
          shot: newShot,
          allShots: matchesAfter,
          threshold: this.props.machineCx.machine.training_threshold,
          requireConfidence: requireConfidence,
        })
      : undefined;

    nextState.step = nextStep;
    logData.step = nextStep;

    if (newShot) {
      // every shot gets a training_complete flag, but the value is only true when complete
      newShot.training_complete = [nextStep, nextOptimizedStep].includes(
        PrecisionTrainStep.Complete
      );

      await this.props.matchingCx.updateShot({
        matching_hash: aimed.ms.matching_hash,
        shot_id: newShot._id,
        payload: { training_complete: newShot.training_complete },
      });
    }

    if (SEEK_IN_PROGRESS_STEPS.includes(nextStep)) {
      // increment the attempt counter on CL step
      nextState.currentIteration = this.state.currentIteration + 1;
    }

    // we found the ms, update the pitch
    if (SEEK_RESULT_STEPS.includes(nextStep)) {
      nextState.optimized = true;

      const ms = getMSFromMSDict(pitch, this.props.machineCx.machine).ms;

      if (ms) {
        if (nextStep === PrecisionTrainStep.SeekSuccess) {
          ms.precision_trained = OPTIONS.find((o) => o.mode === this.state.mode)
            ?.precisionTrained;
        }

        await this.props.listsCx.updatePitches({
          payloads: [
            {
              _id: pitch._id,
              msDict: pitch.msDict,
            },
          ],
        });
      }
    }

    if ([PrecisionTrainStep.Sending, ...SEEK_RESULT_STEPS].includes(nextStep)) {
      // do nothing, just proceed
    } else if (newShot && SEEK_IN_PROGRESS_STEPS.includes(nextStep)) {
      // run an iteration of the closed loop logic, if we haven't run out of attempts
      const target_bs = pitch.bs;
      if (!target_bs) {
        NotifyHelper.error({
          message_md: 'Cannot proceed without a target ball state.',
        });
        return;
      }

      const summary_bs = SUMMARY_FN(
        matchesAfter.filter((s) => s.bs).map((s) => s.bs as IBallState)
      ) as IBallState;

      if (!summary_bs) {
        NotifyHelper.error({
          message_md: 'Cannot proceed without a summary ball state.',
        });
        return;
      }

      const traj = pitch.traj;
      if (!traj) {
        NotifyHelper.error({
          message_md: 'Cannot proceed without a trajectory.',
        });
        return;
      }

      const priority = pitch.priority ?? BuildPriority.Spins;

      if (priority === BuildPriority.Breaks) {
        if (!pitch.breaks) {
          NotifyHelper.error({
            message_md: 'Cannot proceed without target breaks from pitch.',
          });
          return;
        }

        if (!newShot.break) {
          NotifyHelper.error({
            message_md: 'Cannot proceed without actual breaks from shot.',
          });
          return;
        }
      }

      const summary_break = SUMMARY_FN(
        matchesAfter.filter((s) => s.break).map((s) => s.break as IRapsodoBreak)
      ) as IRapsodoBreak;

      const originalMS = getMSFromMSDict(
        pitch,
        this.props.machineCx.machine
      ).ms;

      if (!originalMS) {
        NotifyHelper.error({
          message_md:
            'Cannot proceed without original machine state from pitch.',
        });
        return;
      }

      const chars: IClosedLoopBuildChars = {
        machineID: this.props.machineCx.machine.machineID,
        mongo_id: pitch._id,
        use_gradient: this.state.useGradient,
        ms: originalMS,
        traj: traj,
        target_bs: target_bs,
        actual_bs: summary_bs,
        actual_mvmt: {
          break_x_ft: -1 * summary_break.PITCH_HBTrajectory * METERS_TO_FT,
          break_z_ft: summary_break.PITCH_VBTrajectory * METERS_TO_FT,
        },
        target_mvmt: pitch.breaks
          ? {
              break_x_ft: pitch.breaks.xInches / FT_TO_INCHES,
              break_z_ft: pitch.breaks.zInches / FT_TO_INCHES,
            }
          : undefined,
        priority: priority,
      };

      logData.chars = chars;

      NotifyHelper.success({
        message_md: `Attempting optimization of pitch ${
          this.state.index + 1
        }...`,
        delay_ms: 3_000,
      });

      const result = (
        await StateTransformService.getInstance().buildClosedLoop({
          machineID: this.props.machineCx.machine.machineID,
          pitches: [chars],
          stepSize: this.state.closedLoopStepSize,
          notifyError: true,
        })
      ).find((p) => p.mongo_id === pitch._id);

      if (!result) {
        NotifyHelper.error({
          message_md: 'Failed to find adjusted pitch by ID.',
        });
        return;
      }

      // update the ms
      pitch.msDict = getMergedMSDict(
        this.props.machineCx.machine,
        [result.ms],
        pitch.msDict
      );

      // just in case editing the pitch in place doesn't actually "save" the changes for the next iteration
      const nextPitches = [...this.state.pitches];
      nextPitches[this.state.index] = { ...pitch };
      nextState.pitches = nextPitches;

      // assumption is there can't be matches for this yet since it's a brand new ms
      nextState.matches = [];

      await this.props.aimingCx.setPitch(pitch as IPitch, true);
    }

    this.setState(nextState as any, async () => {
      if (
        nextState.step &&
        [...SEEK_IN_PROGRESS_STEPS, ...SEEK_RESULT_STEPS].includes(
          nextState.step
        )
      ) {
        SessionEventsService.postEvent({
          category: 'pitch',
          tags: 'precision train',
          data: logData,
        });
      }

      // always resend since shots for aiming will have changed
      await this.sendPitch(`after setState (${nextStep})`);

      this.plate?.drawShots(`${COMPONENT_NAME} > updated matches`);

      switch (nextStep) {
        case PrecisionTrainStep.Sending: {
          // timeout should prevent auto-fire from prematurely firing
          clearTimeout(this.sendTimeout);

          this.sendTimeout = setTimeout(() => {
            this.setState({ step: PrecisionTrainStep.Firing });
          }, 500);
          break;
        }

        case PrecisionTrainStep.Complete: {
          this.handlePitchTrained();
          break;
        }

        case PrecisionTrainStep.SeekBreaks:
        case PrecisionTrainStep.SeekSpeed:
        case PrecisionTrainStep.SeekSpins: {
          // longer timeout should prevent auto-fire from prematurely firing
          clearTimeout(this.stepTimeout);

          this.stepTimeout = setTimeout(() => {
            this.setState({ step: PrecisionTrainStep.Firing });
          }, STEP_TIMEOUT_MS);
          break;
        }

        case PrecisionTrainStep.SeekFailure:
        case PrecisionTrainStep.SeekSuccess: {
          // longer timeout should prevent auto-fire from prematurely firing
          clearTimeout(this.stepTimeout);

          const isComplete = PrecisionTrainStep.Complete === nextOptimizedStep;

          this.stepTimeout = setTimeout(() => {
            this.setState(
              {
                step: isComplete
                  ? PrecisionTrainStep.Complete
                  : PrecisionTrainStep.Firing,
              },
              () => {
                if (isComplete) {
                  this.handlePitchTrained();
                }
              }
            );
          }, STEP_TIMEOUT_MS);
          break;
        }

        default: {
          break;
        }
      }
    });
  }

  /** can be used by parent components (e.g. model builder) to skip a pitch or return to a previous pitch */
  async changePitch(delta: ProgressDirection) {
    this.fireButton?.resetR2FWait(`${COMPONENT_NAME} > changePitch`);

    const nextIndex = this.state.index + delta;

    if (nextIndex < 0 || nextIndex >= this.state.pitches.length) {
      // avoid changing to illegal indices
      return;
    }

    await this.props.aimingCx.setPitch(
      this.state.pitches[nextIndex] as IPitch,
      true
    );

    this.setState(
      {
        step: PrecisionTrainStep.Waiting,
        index: nextIndex,
        optimized: false,
        matches: [],
        prevMatches: [],
        currentIteration: 0,
      },
      () => {
        this.updateMatchesAndActive('changePitch');
      }
    );
  }

  private renderStepDescription() {
    const content = (() => {
      switch (this.state.step) {
        case PrecisionTrainStep.Sending: {
          return <p>{t('tr.wait-for-r2f-msg')}</p>;
        }

        case PrecisionTrainStep.Waiting: {
          return <p>{t('tr.wait-for-data-msg')}</p>;
        }

        case PrecisionTrainStep.Firing: {
          return (
            <p>
              {this.props.machineCx.autoFire
                ? t('tr.auto-fire-when-ready-msg')
                : t('tr.fire-when-ready-msg')}
            </p>
          );
        }

        case PrecisionTrainStep.SeekBreaks: {
          return <p>{t('tr.seek-breaks-msg')}</p>;
        }

        case PrecisionTrainStep.SeekSpins: {
          return <p>{t('tr.seek-spins-msg')}</p>;
        }

        case PrecisionTrainStep.SeekSpeed: {
          return <p>{t('tr.seek-speed-msg')}</p>;
        }

        case PrecisionTrainStep.SeekSuccess: {
          if (this.state.currentIteration === 0) {
            return <p>{t('tr.seek-success-msg')}</p>;
          }

          return (
            <p>
              {t('tr.seek-success-after-x-msg', {
                x: this.state.currentIteration + 1,
              })}
            </p>
          );
        }

        case PrecisionTrainStep.SeekFailure: {
          return (
            <p>
              {t('tr.seek-failure-after-x-msg', {
                x: this.state.currentIteration + 1,
              })}
            </p>
          );
        }

        case PrecisionTrainStep.Complete: {
          return (
            <>
              <p>{t('tr.pitch-trained-msg')}</p>
              <p>
                {this.state.index === this.state.pitches.length - 1
                  ? t('tr.complete-msg')
                  : t('tr.click-next-msg')}
              </p>
            </>
          );
        }

        default: {
          return;
        }
      }
    })();

    // provide min-height to avoid moving up and down when content disappears
    return (
      <div className="align-center" style={{ minHeight: '50px' }}>
        {content}
      </div>
    );
  }

  private isLastPitch() {
    return this.state.index === this.state.pitches.length - 1;
  }

  private renderSendButton() {
    return (
      <DialogButton
        color={RADIX.COLOR.SEND_PITCH}
        className={FOOTER_BTN_CLASS}
        onClick={() => this.sendPitch('renderSendButton')}
        label="common.send-to-machine"
      />
    );
  }

  private renderFireButton() {
    const firing = this.state.step === PrecisionTrainStep.Firing;

    return (
      <MachineFireButton
        ref={(elem) => (this.fireButton = elem as MachineFireButton)}
        as="dialog-button"
        size={RADIX.BUTTON.SIZE.SM}
        className={FOOTER_BTN_CLASS}
        cookiesCx={this.props.cookiesCx}
        ignoreAutoFire={this.state.ignoreAutoFire}
        machineCx={this.props.machineCx}
        aimingCx={this.props.aimingCx}
        trainingCx={this.props.trainingCx}
        firing={firing}
        tags="PRECISION"
        beforeFire={(_, isReady) => {
          if (isReady) {
            this.setState({ step: PrecisionTrainStep.Waiting });
          }
        }}
        onReady={() => {
          if (!this.fireButton) {
            // shouldn't trigger but typescript needs to be satisfied
            return;
          }

          if (
            firing &&
            this.props.machineCx.autoFire &&
            !this.state.ignoreAutoFire
          ) {
            this.fireButton.performFire('auto', COMPONENT_NAME);
            return;
          }

          // fallback
          this.fireButton.goToReady();
        }}
      />
    );
  }

  private renderNextButton() {
    if (this.isLastPitch()) {
      return ENABLE_FINISH_BUTTON ? (
        <DialogButton
          className={FOOTER_BTN_CLASS}
          color={
            this.props.aimingCx.pitch &&
            this.props.matchingCx.isPitchTrained(this.props.aimingCx.pitch)
              ? RADIX.COLOR.SUCCESS
              : undefined
          }
          onClick={() => {
            if (this.props.beforeNext) {
              this.props.beforeNext();
            }

            if (this.props.onFinish) {
              this.props.onFinish();
            }
          }}
          label="tr.finish"
        />
      ) : (
        <></>
      );
    }

    const willAutoFire =
      this.props.machineCx.autoFire && !this.state.ignoreAutoFire;

    const label =
      this.state.step === PrecisionTrainStep.Complete
        ? willAutoFire
          ? 'common.loading'
          : 'common.next'
        : 'common.skip';

    return (
      <DialogButton
        disabled={willAutoFire}
        className={FOOTER_BTN_CLASS}
        onClick={() => {
          if (this.props.beforeNext) {
            this.props.beforeNext();
          }

          this.changePitch(ProgressDirection.Next);
        }}
        label={label}
      />
    );
  }

  private getProgress(): ITrainingProgress {
    if (this.state.index === undefined) {
      return EMPTY_PROGRESS;
    }

    const pitch = this.props.aimingCx.pitch;

    if (!pitch) {
      return EMPTY_PROGRESS;
    }

    const pTotal = this.state.pitches.length;
    const pComplete = this.state.index;
    const pIncomplete = pTotal - pComplete;

    const left_text = [
      pitch.name ?? 'Unknown',
      `(${pComplete + 1} of ${pTotal})`,
    ].join(' ');

    const etaMinutes = getETAForShots(
      pIncomplete * this.state.spec.sampleSize,
      'min'
    );

    const output: ITrainingProgress = {
      left_text: left_text,
      right_text: `est. ${etaMinutes} min remaining`,
      complete: (100 * pComplete) / pTotal,
      paused: this.state.loading,
      label: this.state.loading ? 'Please wait...' : undefined,
    };

    return output;
  }

  private setSpec(spec: Partial<IPresetTrainingSpec>) {
    this.setState(
      {
        spec: {
          ...this.state.spec,
          ...spec,
        },
      },
      () => console.debug(this.state.spec)
    );
  }

  render() {
    const pitch = this.props.aimingCx.pitch;

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Flex className="PTControls" direction="column" gap={RADIX.FLEX.GAP.MD}>
          {this.props.showProgress && (
            <Box>
              <CommonTrainingProgress progress={this.getProgress()} />
            </Box>
          )}

          <Flex gap={RADIX.FLEX.GAP.MD}>
            <Flex direction="column" gap={RADIX.FLEX.GAP.SM} flexGrow="1">
              {this.renderStepDescription()}

              <PresetTrainingPlateView
                ref={(elem) => (this.plate = elem as PresetTrainingPlateView)}
                cookiesCx={this.props.cookiesCx}
                machineCx={this.props.machineCx}
                step={this.state.step}
                centeredPitch={pitch as IPitch}
                newShotID={this.state.newShot?._id}
                shots={this.state.matches}
                // when user confirms inputs and a shot gets updated (e.g. with user_traj)
                onUpdateShot={async (shot) => {
                  if (!shot) {
                    // continued without setting override for shot (e.g. timeout without manual input)
                    this.setState({ step: PrecisionTrainStep.Firing });
                    return;
                  }

                  this.updateMatchesAndActive(
                    'TrainingPlateView > onUpdateShot'
                  );
                }}
              />

              <DetectionFailedCallout
                onOpenSettings={() => this.props.onFinish?.()}
              />
            </Flex>

            <Flex width="390px" direction="column" gap={RADIX.FLEX.GAP.MD}>
              {this.renderPresets()}

              {this.state.currentIteration > 0 && (
                <CommonCallout
                  color={RADIX.COLOR.INFO}
                  icon={<InfoCircledIcon />}
                  text={t('tr.on-iteration-x-msg', {
                    x: this.state.currentIteration + 1,
                  }).toString()}
                />
              )}
            </Flex>
          </Flex>

          {pitch && (
            <DataTable
              sampleSize={this.state.spec.sampleSize}
              priority={pitch.priority ?? BuildPriority.Spins}
              targetBS={pitch.bs}
              targetBreaks={pitch.breaks}
              // Display previous matches if there are no current matches
              actualShots={
                this.state.matches.length > 0
                  ? this.state.matches
                  : this.state.prevMatches
              }
            />
          )}

          {this.renderFooterButtons()}
        </Flex>
      </ErrorBoundary>
    );
  }

  private renderFooterButtons() {
    return (
      <Flex gap={RADIX.FLEX.GAP.SM}>
        {this.props.left_button && (
          <DialogButton
            className={FOOTER_BTN_CLASS}
            tooltip={this.props.left_button.tooltip}
            disabled={this.props.left_button.disabled}
            color={this.props.left_button.color}
            onClick={this.props.left_button.onClick}
            label={this.props.left_button.label}
          />
        )}

        <Box flexGrow="1">&nbsp;</Box>

        {this.props.machineCx.lastBallCount !== 1 && (
          <DialogButton
            label="common.lower-machine"
            className={FOOTER_BTN_CLASS}
            color={RADIX.COLOR.WARNING}
            onClick={() =>
              this.props.machineCx.specialMstarget(SpecialMsPosition.lowered)
            }
          />
        )}

        {this.props.machineCx.lastBallCount === 1 &&
          this.props.machineCx.getAutoFireButton({
            className: FOOTER_BTN_CLASS,
            beforeToggleFn: (newValue) =>
              this.setState({ ignoreAutoFire: newValue }),
            as: 'dialog-button',
          })}

        {this.props.machineCx.lastMSHash
          ? this.renderFireButton()
          : this.renderSendButton()}

        {!this.props.hideNext && this.renderNextButton()}
      </Flex>
    );
  }

  private renderPresets() {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        {OPTIONS.map((preset, i) => {
          const active = preset.mode === this.state.mode;

          const onSelect = () => {
            if (preset.mode === this.state.mode) {
              // do nothing, otherwise clicking would reapply the default spec and override form inputs
              return;
            }

            const nextSpec: IPresetTrainingSpec = {
              // deserialize to avoid overwriting the original constant (e.g. for changes to the inputs)
              ...preset.spec,
              // ensure actual sample size will never be less than training threshold
              sampleSize: Math.max(
                this.props.machineCx.machine.training_threshold,
                preset.spec.sampleSize
              ),
            };

            this.setState(
              {
                mode: preset.mode,
                spec: nextSpec,
              },
              () => {
                // automatically save the selected spec to the user record
                this.props.authCx.updateUser(
                  { preset_training_mode: preset.mode },
                  true
                );
              }
            );
          };

          return (
            <Card key={`pt-preset-${i}`}>
              <Flex gap={RADIX.FLEX.GAP.SM}>
                <Box style={{ paddingTop: '2px' }}>
                  <CommonRadio
                    id="precision-training-priority"
                    name="priority"
                    options={[
                      {
                        label: '',
                        value: preset.mode,
                      },
                    ]}
                    value={this.state.mode}
                    onChange={onSelect}
                  />
                </Box>
                <Box flexGrow="1">
                  <Flex direction="column" gap="2">
                    <Flex
                      gap="2"
                      justify="between"
                      className="cursor-pointer"
                      onClick={onSelect}
                    >
                      <Heading
                        color={active ? RADIX.COLOR.ACCENT : undefined}
                        size={RADIX.HEADING.SIZE.MD}
                      >
                        {tPresetMode(preset.mode)}
                      </Heading>
                      {preset.minPerPitch && (
                        <Badge>
                          {t('tr.x-min-per-pitch', { x: preset.minPerPitch })}
                        </Badge>
                      )}
                    </Flex>

                    {active && (
                      <Box>
                        <Text color={RADIX.COLOR.SECONDARY}>
                          {tPresetDescription(preset.mode)}
                        </Text>
                      </Box>
                    )}

                    {active && this.renderControls(preset)}
                  </Flex>
                </Box>
              </Flex>
            </Card>
          );
        })}
      </Flex>
    );
  }

  private renderControls(preset: IPresetOption) {
    if (!preset.showControls) {
      return;
    }

    const pitch = this.props.aimingCx.pitch;

    return (
      <Box pb="2">
        <CommonFormGrid columns={1}>
          <CommonSelectInput
            id="precision-training-samples"
            name="sampleSize"
            label="tr.samples"
            options={this.state.sampleSizes}
            value={this.state.spec.sampleSize.toString()}
            onNumericChange={(v) => {
              if (v <= 0) {
                return;
              }

              this.setSpec({
                sampleSize: v,
              });

              this.setState({
                closedLoopStepSize: getStepSize(v),
              });
            }}
            skipSort
          />

          <CommonSelectInput
            id="precision-training-iterations"
            name="iterations"
            label="tr.max-machine-adjustments"
            options={this.state.adjustments}
            value={this.state.spec.iterations.toString()}
            onNumericChange={(v) => {
              if (v <= 0) {
                return;
              }

              this.setSpec({
                iterations: v,
              });
            }}
            skipSort
          />

          {ENABLE_ADVANCED_CONTROLS && (
            <CommonTextInput
              id="precision-training-step-size"
              label="tr.step-size"
              type="number"
              name="closedLoopStepSize"
              value={this.state.closedLoopStepSize.toString()}
              title="Provide a positive number"
              onNumericChange={(v) => {
                this.setState({
                  closedLoopStepSize: clamp(v, 0.01, 1),
                });
              }}
            />
          )}

          <CommonTextInput
            id="precision-training-max-speed"
            label="tr.max-speed-delta-mph"
            type="number"
            name="deltaSpeedMPH"
            value={this.state.spec.deltaSpeedMPH.toString()}
            title="Provide a positive number"
            onNumericChange={(v) => {
              this.setSpec({
                deltaSpeedMPH: Math.abs(v),
              });
            }}
          />

          {pitch?.priority === BuildPriority.Breaks && (
            <CommonTextInput
              id="precision-training-max-breaks"
              label="tr.max-breaks-delta-inches"
              type="number"
              name="deltaBreaksInches"
              value={this.state.spec.deltaBreaksInches.toString()}
              title="Provide a positive number"
              onNumericChange={(v) => {
                this.setSpec({
                  deltaBreaksInches: Math.abs(v),
                });
              }}
            />
          )}

          {pitch?.priority === BuildPriority.Spins && (
            <CommonTextInput
              id="precision-training-max-spin"
              label="tr.max-spins-delta-rpm"
              type="number"
              name="deltaSpinsRPM"
              value={this.state.spec.deltaSpinsRPM.toString()}
              title="Provide a positive number"
              onNumericChange={(v) => {
                this.setSpec({
                  deltaSpinsRPM: Math.abs(v),
                });
              }}
            />
          )}

          {ENABLE_ADVANCED_CONTROLS && (
            <CommonSwitchInput
              id="precision-training-gradient"
              label="tr.use-gradient"
              name="useGradient"
              defaultChecked={this.state.useGradient}
              onCheckedChange={(v) => this.setState({ useGradient: v })}
            />
          )}

          {this.props.authCx.current.role === UserRole.admin &&
            preset.showProbability && (
              <CommonSwitchInput
                id="precision-training-probability"
                label="tr.use-probability"
                labelIcon={<SuperAdminIcon />}
                name="useProbability"
                title="common.super-admins-only-msg"
                defaultChecked={this.state.spec.useProbability}
                onCheckedChange={(v) => this.setSpec({ useProbability: v })}
              />
            )}
        </CommonFormGrid>
      </Box>
    );
  }
}
